@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-slider .slick-dots {
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
  position: absolute;
  flex-direction: column;
  display: flex;
  top: 0;
  width: 1px;
  justify-content: center;
  right: 19%;
}

.down-enter-active {
  animation: 1s down-enter;
}
.down-leave-active {
  animation: 1s down-leave;
}

@keyframes down-enter {
  0% {
    clip-path: inset(0 0 0);
  }
  100% {
    clip-path: inset(0);
  }
}

@keyframes down-leave {
  0% {
    clip-path: inset(0);
  }
  100% {
    clip-path: inset(100% 0 0 0);
  }
}

p {
  text-align: justify;
}

.down-enter-active-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 66.66%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  clip: rect(0, 80rem, 50rem, 80rem);
  transition: clip 0.5s cubic-bezier(0.99, 0.01, 0.45, 0.9) 0.5s;
  will-change: clip;
}
.slick-active .down-enter-active-1 {
  clip: rect(0, 80rem, 50rem, 0);
}
.client-slider .slick-list {
  width: calc(100% - 8.6%);
  margin: 0 auto;
}
.left-unset {
  left: unset !important;
  color: #000 !important;
  right: 29%;
  top: 80% !important;
}

.right-unset {
  left: unset !important;
  color: #000 !important;
  right: 10% !important;
  top: 80% !important;
}
.left-unset::before,
.right-unset::before {
  display: none;
}

.description {
  opacity: 0;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  transition: opacity 0.5s 0.1s, -webkit-transform 0.5s 0.1s;
  transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
  transition: transform 0.5s 0.1s, opacity 0.5s 0.1s,
    -webkit-transform 0.5s 0.1s;
  will-change: transform, opacity;
}

.slick-active .description {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition-delay: 1.1s;
}

.card--8 {
  --mouseX8: 0;
  --mouseY8: 0;
  --padding: 1em;

  position: relative;
  /* background: #fff; */
}
/* .card--8::before {
    display: block;
    position: absolute;
    top: calc(var(--padding) * -1);
    bottom: calc(var(--padding) * -1);
    left: calc(var(--padding) * -1);
    right: calc(var(--padding) * -1);
    content: ' ';
    z-index: -1;
    background: #fff;
    transform: perspective(700px) rotateX(calc(var(--mouseY8) * -1deg)) rotateY(calc(var(--mouseX8) * 1deg));
  } */

@media only screen and (max-width: 600px) {
  .down-enter-active-1 {
    height: auto;
    width: 100%;
    padding-bottom: 5rem;
  }
  .slick-arrow {
    top: 95% !important;
    z-index: 1200;
  }
  .client-slider .slick-list {
    width: 100%;
  }
}
section {
  overflow: hidden;
}
.w-custom-width {
  width: 95% !important;
}
.map-icon path {
  fill: #181b31;
}

.curve {
  /* color: white; */
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 3rem 2rem;
  width: 100%;
  z-index: 2;
}

.curve::before {
  content: "";
  display: block;
  background: radial-gradient(
    ellipse at center,
    rgba(241, 245, 249, 0.1) 25%,
    rgba(241, 245, 249, 1) 100%
  );
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 150vw;
  width: 300vw;
  height: 400vw;
  /* background-size: cover; */
  object-fit: cover;
  background-repeat: no-repeat;
  background-image: url("https://img.freepik.com/free-photo/business-people-shaking-hands-greeting_53876-96074.jpg?t=st=1681394418~exp=1681395018~hmac=5d9bfc57a465a53e3da42450c6b0ffdab5b72326fd3a0757fbbd111b03684f11");
  z-index: -1;
}

.social-icon ul {
  display: flex;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.social-icon ul li {
  list-style: none;
}

.social-icon ul li a {
  width: 40px;
  height: 40px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

.social-icon ul li a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.social-icon ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}
.social-icon ul li a:hover .icon svg path,
.social-icon ul li a:hover .icon svg circle {
  fill: #fff;
}

.social-icon ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

.social-icon ul li a:hover:before {
  top: 0;
}

.social-icon ul li:nth-child(1) a:before {
  background: #3b5999;
}

.social-icon ul li:nth-child(2) a:before {
  background: #55acee;
}

.social-icon ul li:nth-child(3) a:before {
  background: #0077b5;
}

.social-icon ul li:nth-child(4) a:before {
  background: #962fbf;
}

.slick-track{
  display: flex !important;
}